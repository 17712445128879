import React, { Component } from 'react';
import Select from 'react-select';
// import CheckBox from '../../elements/CheckBox';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { default as config } from '../../../config/config';
import _ from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';
import * as helper from '../../../config/helper';
import { bindActionCreators } from 'redux';
import { LeadActions } from "../../../store/action";

class RecommendSearchcar extends Component {
    constructor(props) {
        super(props);
        
        this.multiselectRef={
            'fuel_type':React.createRef(),
            'model_ids':React.createRef(),
            'city':React.createRef(),
            'customer_locality':React.createRef(),
            'rto_code_city':React.createRef(),
            'owner':React.createRef(),
            'req_body_type':React.createRef(),
            'color':React.createRef(),
            'dealer':React.createRef(),
            'dealer_type':React.createRef(),
            'car_make' : React.createRef(),
            'fuel_type_id' : React.createRef(),
            'body_type_id' : React.createRef(),
            'car_model' : React.createRef(),
            'uc_transmission_id' : React.createRef()
            
        }
        this.state = {
            reqform:true,
            startDate: new Date(),
            open:true,
            openemail:false,
            openmobile:false,
            showhistory: false,
            selectedOption: null,
            advanceSearchEnable: false,
            filterData: props.filterData,
            leadInfo : props.leadDetailsData,
            formData:props.formData,
            multipleSelectedData:{},
            leadDetails:{},
            models:[],
            make:[],
            selectedModel : [],
            selectedTransmission : [],
            dealers:[],
            selectedDealer:[],
            dealer_type:[],
            selected_dealer_type:[],
            city:[],
            clusterListData:[],
            iterate:1,
            iterateCustomerCity:1,
            iterateDealers:1,
            resetIterate:true,
            loading: true
      };
      this.handleChange = this.handleChange.bind(this)
      this.togglePanel = this.togglePanel.bind(this);
     // this.addemail = this.addemail.bind(this);
      
    }

    componentDidMount = async () => {
       
    }


    static getDerivedStateFromProps = (nextProps, prevState) => {
        let {leadInfo,dealers} = prevState;
        const {filterData, filterList, formData,makeModelVersionList:{make,model} = {}} = nextProps;
        let allMake = [], allModel=[], color=[];
        let updateData = prevState;
        if(make && make.length){
            allMake = make.map(function(e){
                return {label : e.make, value : e.id}
            })
        }

        if(filterList && filterList.length > 0){
            let colorOptions = filterList.filter(allData=> allData.key==='color');
            color = colorOptions[0].list.map(function(e){
                    return {label : e.value, value : e.key}
                });
            updateData['filterData']['color']=color; 
                
        }
       
        // if(filterList && filterList.color.length){
        //     color = filterList.color.map(function(e){
        //         console.log('e------------------', e);
        //         return {label : e.name, value : e.key}
        //     });
        //     updateData['filterData']['color']=color; 
        // }        
        updateData['btnStatus'] = !nextProps.loading;
        if(nextProps.resetSearchForm && prevState.resetIterate){
            updateData['formData']=formData; 
            updateData['resetIterate']=false; 
        }else{
            // updateData['formData']=prevState.formData; 
        }
        updateData['filterData']['make']=allMake; 

        if(model && model.length){
            allModel = model.map(function(e){
                return {label : e.m, value : e.id}
            })
        }        
        updateData['filterData']['model']=allModel; 

        if(nextProps.dealerListData && nextProps.dealerListData['data'] && nextProps.dealerListData['data'].length && prevState.iterateDealers === 1){
            // dealers = helper.getDataInKeyValueFormat(nextProps.dealerListData['data'],'');
            dealers = nextProps.dealerListData['data'];

            
            if(updateData['formData']['city'] && updateData['dealers'].length){
                leadInfo = nextProps.leadDetailsData[0];
                let dealers = updateData['dealers'].filter(el=>el.city_id===leadInfo['customer_city_id'])
                updateData['filterData']['dealers'] = helper.getDataInKeyValueFormat(dealers);
                updateData['iterateDealers'] = 2;
            }

        }
        // updateData['filterData']['dealers'] = dealers;
        updateData['dealers'] = dealers;
        
        //TO RUN ONLY ONCE
        if(prevState.iterate === 1){
            if(nextProps.customerLocalityList && nextProps.customerLocalityList.length ){
                updateData['clusterListData'] = helper.getDataInKeyValueFormat(nextProps.customerLocalityList);
                updateData['iterate'] = 2;
            }
        }

        if(prevState.iterateCustomerCity === 1){
            if(nextProps.leadDetailsData && nextProps.leadDetailsData.length ){
                leadInfo = nextProps.leadDetailsData[0];
                updateData['formData']['city'] = [leadInfo['customer_city_id']];
                updateData['iterateCustomerCity'] = 2;
                
            }
            updateData['leadInfo'] = leadInfo;
            updateData['leadDetails'] = leadInfo;
        }
            //IF BOTH ABOVE UPDATED THEN INCREMENT COUNTER
            // if(incrementIterateForFormData || incrementIterateForClusterList){
            //     updateData['iterate'] = 2;
            // }
        updateData['filterData'] = filterData;

        return updateData;
    }

    // UNSAFE_componentWillReceiveProps = async (nextProps) =>{
    //     let {leadInfo,dealers} = this.state;
    //     const {filterData,formData,makeModelVersionList:{make} = {}} = nextProps;
    //     let allMake = [];
    //     if(make && make.length){
    //         allMake = make.map(function(e){
    //             return {label : e.make, value : e.id}
    //         })
    //     }
        
    //     filterData['make']=allMake; 
        
        
    //     if(nextProps.dealerListData && nextProps.dealerListData['data'] && nextProps.dealerListData['data'].length){
    //         dealers = helper.getDataInKeyValueFormat(nextProps.dealerListData['data'],'');
    //     }
    //     // if(nextProps.customerLocalityList && nextProps.customerLocalityList.length){
    //     //     this.setState({filterData: {...this.state.filterData, customerLocalityList: helper.getDataInKeyValueFormat(nextProps.customerLocalityList)}})
    //     // }else{
    //     //     this.setState({filterData: {...this.state.filterData}})
    //     // }

        
    //     leadInfo = (nextProps.leadDetailsData && nextProps.leadDetailsData.length) ? nextProps.leadDetailsData[0] : {}
    //     this.setState({filterData:filterData,leadInfo:leadInfo,formData:formData,leadDetails:leadInfo,dealers});
        
    // }

    componentDidUpdate = async(prevProps) =>{
        let {formData} = this.state;
        await this.resetMultiSelectedForm(formData);
        
    }

    resetMultiSelectedForm = async(formData) =>{
        if(!('car_make' in formData))  this.multiselectRef.car_make.current.resetSelectedValues();
        if(!('fuel_type_id' in formData))  this.multiselectRef.fuel_type_id.current.resetSelectedValues();
        if(!('car_model' in formData))  this.multiselectRef.car_model.current.resetSelectedValues();
        if(!('body_type_id' in formData))  this.multiselectRef.body_type_id.current.resetSelectedValues();
        // if(!('dealer_ids' in formData))  this.multiselectRef.dealer_ids.current.resetSelectedValues();
        // if(!('uc_transmission_id' in formData))  this.multiselectRef.uc_transmission_id.current.resetSelectedValues();
        // if(!('fuel_type' in formData))
        //     this.multiselectRef.fuel_type.current.resetSelectedValues();
        // if(!('model_ids' in formData)) this.multiselectRef.model_ids.current.resetSelectedValues();
        if(!('city' in formData))  this.multiselectRef.city.current.resetSelectedValues();
        //if(!('customer_locality' in formData))  this.multiselectRef.customer_locality.current.resetSelectedValues();
        // if(!('rto_code_city' in formData)) this.multiselectRef.rto_code_city.current.resetSelectedValues();
        // if(!('owner' in formData)) this.multiselectRef.owner.current.resetSelectedValues();
        // if(!('req_body_type' in formData)) this.multiselectRef.req_body_type.current.resetSelectedValues();
        if(!('color' in formData)) this.multiselectRef.color.current.resetSelectedValues();
        // if(!('dealer' in formData)) this.multiselectRef.dealer.current.resetSelectedValues();
        if(!('dealer_type' in formData)) this.multiselectRef.dealer_type.current.resetSelectedValues();
    }

    historyCloseModal = () => {
        this.setState({ showhistory: false });
    }

    historyshowModal = () => {
        this.setState({ showhistory: true });
    }
    
    togglePanel(e){
        this.setState({open: !this.state.open})
    }
        
    ToogleAdvanceSearch = () => {
        let advanceSearchEnable = this.state.advanceSearchEnable;
        if (advanceSearchEnable) {
            this.setState({ advanceSearchEnable: false });
        } else {
            this.setState({ advanceSearchEnable: true });
        }
    };

    handleChange = (selectedKey,event) => {
        let formData = {...this.state.formData};
        formData[selectedKey] = event ? event.value : '';
        this.setState({ formData:formData });
    };

    submitForm = (e) => {                
        e.preventDefault();        
    }
    handleModalCall = (selectedItem) => {
        
        let allModels = []
        let {model} = this.props.makeModelVersionList;
        if(model && model.length){
            allModels = model.filter(e=>selectedItem.includes(e.mk_id))
            allModels = allModels.map(function(e){
                return {label : e.m,value : e.id,make_id : e.mk_id}
            })
        };
        this.setState({models:allModels});
    }
    handleModelSelect = (selectedValue,makeSelected = null) => {
        let {models,selectedModel} = this.state
        let selectedModels = selectedModel;
        if(selectedValue){
            selectedModels = models.filter(e=>selectedValue.includes(e.value));
        }
        if(makeSelected && selectedModel && selectedModel.length){
            selectedModels=selectedModels.filter(e=>makeSelected.includes(e.make_id))
        }

        this.setState({selectedModel:selectedModels})
    }

    handleDealerSelect = (selectedValue, makeSelected = null) => {
        let { dealers, selectedDealer } = this.state
        let selectedDealers = selectedDealer;
        if (selectedValue) {
            selectedDealers = dealers.filter(e => selectedValue.includes(e.value));
        }
        // if(makeSelected && selectedModel && selectedModel.length){
        //     selectedModels=selectedModels.filter(e=>makeSelected.includes(e.make_id))
        // }

        this.setState({ selectedDealers: selectedDealers })
    }
    handleDealerTypeSelect = (selectedValue, makeSelected = null) => {
       // let selectedDealerType
        let selectedDealerType;
        if (selectedValue) {
            selectedDealerType = selectedValue.filter(e => selectedValue.includes(e.value));
        }
        this.setState({ selectedDealerType: selectedDealerType })
    }

    handleTransmissionSelect = (existingVal) => {
        // console.log(existingVal);
    }
    onSelect = async (key,selectedList, selectedItem) => {
        let formData = this.state.formData;
        let existingVal = (formData[key]) ? ((typeof formData[key] === 'string') ? formData[key].split(',') : formData[key]) : []

        if(!existingVal.includes(selectedItem.value)) existingVal.push(!Number.isNaN(+selectedItem.value)?+selectedItem.value:selectedItem.value);

        formData[key] = existingVal;
        if(key==='car_make'){
            this.handleModalCall(existingVal);
        }
        if (key === 'car_model') {
            this.handleModelSelect(existingVal);
        }
        if (key === 'dealer_ids') {
            this.handleDealerSelect(existingVal);
        }
        // if (key === 'dealer_type') {
        //     this.handleDealerTypeSelect(existingVal);
        // }
        if(key === 'city'){  
            // formData.city= existingVal;       
            this.getCustomerLocality({city: existingVal});
            this.getCityDealers(existingVal);

        }       
        // if(key==="uc_transmission_id"){
        //     this.handleTransmissionSelect(existingVal);
        // }
        // console.log(formData)
        await this.setState({ formData: formData });
        
        
    }

    getCustomerLocality = (postData) => {
        let param = (postData.city) ? postData.city.filter(el=>+el) : []
        if(!param.length){
            this.setState({clusterListData: [], filterData: {...this.state.filterData, customerLocalityList: []}})
            
        }else{
            this.props.actions.getCustomerLocality({city: param}).then(res=>{ 
                res = res || [];
                this.setState({clusterListData: helper.getDataInKeyValueFormat(res), filterData: {...this.state.filterData, customerLocalityList: helper.getDataInKeyValueFormat(res)}})
            })
        }
       
    }

    getCityDealers = async (selectedCities) => {
        const { dealers, filterData } = this.state;
        let selectedDealers = dealers.filter(el=> {return ((selectedCities).includes(el.city_id)) } )
        filterData['dealers'] = helper.getDataInKeyValueFormat(selectedDealers,'');
         
        await this.setState({filterData})
    }
     
    onRemove(key,selectedList, removedItem) {
        let formData = this.state.formData;
        let removedItemType = typeof(removedItem);
        let existingVal = (key !== 'customer_locality' && formData[key]) ? formData[key] : [];
        if(removedItemType !== 'object'){
            existingVal =  _.without(existingVal, removedItem);
            if(removedItem === 0) {
                existingVal = []; 
            }
        }       
        existingVal = _.filter(existingVal, function(currentVal) {
            return currentVal !== removedItem.value;
        });
        formData[key] = existingVal;
        
        if(key==='car_make'){
            this.handleModalCall(existingVal);
            this.handleModelSelect(null,existingVal);
        }
        if(key==='car_model'){
            this.handleModelSelect(existingVal);
        }
        if(key==='dealer_ids'){
            this.handleDealerSelect(existingVal);
        }
        if(key==='dealer_type'){
            let selectItem = selectedList.map(e=>e.value);
            formData[key] = selectItem;
            this.handleDealerTypeSelect(selectItem);
        }
        if(key==="uc_transmission_id"){
            this.handleTransmissionSelect(existingVal);
        }
        if(key === "city"){
            this.getCustomerLocality({city: existingVal});
            this.getCityDealers(existingVal);
        }
        this.setState({ formData: formData });
        
    }

    getFilterList = () => {
        let {formData} = this.state;     
        formData['page_no'] = 1;
        let newFormData = formData;
        delete newFormData['city_ids'];
        if(formData.city && formData.city.length){
            newFormData['city_ids'] = formData.city
        }
        if(formData.color && formData.color.length){
            newFormData['uc_colour_id'] = formData.color;
        }        
        if (typeof this.props.getSearchList === 'function') {
            this.setState({btnStatus: false});            
            this.props.getSearchList(newFormData);
        }
    }

    advanceSearch = (key) => {
        let {formData} = this.state;
        formData[key]= (key in formData) ? !formData[key] : true;
        this.setState(formData);
        this.getFilterList();
    }

    render() {
        const { filterData,formData, clusterListData, btnStatus} = this.state;
        return (
            <div>
                <div className={this.state.open ? "card timeline-panel open" :"card timeline-panel "}>
                    <div className="card-heading card-headflex" onClick={(e)=>this.togglePanel(e)}>
                        <h2 className="card-title">{this.props.t('recomendTab.search.searchCars')}</h2>
                    </div>
                    <div className="card-body">
                        <form method="post" id="reqForm" onSubmit={this.submitForm}>
                            <div className="row">
                                <div className="col-sm-12 form-group">
                                    <label>{this.props.t('recomendTab.search.budgetRange')}</label>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <Select
                                                id="min_price"
                                                name="min_price"
                                                onChange={this.handleChange.bind(this, 'min_price')}
                                                options={filterData.priceRange}
                                                value={formData && filterData && filterData['priceRange'].filter((budget) => Number(budget.value) === Number(formData.min_price))}
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-xs-6">
                                            <Select
                                                id="max_price"
                                                name="max_price"
                                                onChange={this.handleChange.bind(this, 'max_price')}
                                                options={filterData.priceRange}
                                                value={formData && filterData && filterData['priceRange'].filter((budget) => Number(budget.value) === Number(formData.max_price))}
                                                isClearable
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 form-group">
                                    <label>{this.props.t('recomendTab.search.yearRange')}</label>
                                    <div className="row">
                                        <div className="col-xs-6 ">
                                            <Select
                                                id="min_make_year"
                                                name="min_make_year"
                                                onChange={this.handleChange.bind(this, 'min_make_year')}
                                                options={filterData.fromYearRange}
                                                value={formData && filterData && filterData['fromYearRange'].filter((fromYearRange) => Number(fromYearRange.value) === Number(formData.min_make_year))}
                                                isClearable
                                            />
                                        </div>
                                        <div className="col-xs-6 ">
                                            <Select
                                                id="max_make_year"
                                                name="max_make_year"
                                                onChange={this.handleChange.bind(this, 'max_make_year')}
                                                options={filterData.toYearRange}
                                                value={formData && filterData && filterData['toYearRange'].filter((toYearRange) => Number(toYearRange.value) === Number(formData.max_make_year))}
                                                isClearable
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 form-group">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label>{this.props.t('recomendTab.search.maxKms')}</label>
                                            <Select
                                                id="max_km_driven"
                                                name="max_km_driven"
                                                onChange={this.handleChange.bind(this, 'max_km_driven')}
                                                options={filterData.kmRange}
                                                value={formData && filterData && filterData['kmRange'].filter((kmRange) => Number(kmRange.value) === Number(formData.max_km_driven))}
                                                isClearable
                                            />
                                        </div>

                                        <div className="col-sm-6 form-group">
                                            <label>{this.props.t('recomendTab.search.transmission')}</label>
                                            <Select
                                                id="transmission"
                                                name="transmission"
                                                onChange={this.handleChange.bind(this,'uc_transmission_id')}
                                                options={filterData.transmission}
                                                value={formData && filterData && filterData['transmission'].filter((transmission) => Number(transmission.value) === Number(formData.uc_transmission_id))}
                                                isClearable
                                            />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                                <div className="row">
                                <div className="col-sm-12 form-group">
                                        <label>{this.props.t('recomendTab.search.bodyType')}</label>
                                        <Multiselect
                                            id='body_type_id'
                                            options={filterData.bodyType}
                                            onSelect={this.onSelect.bind(this, 'body_type_id')}
                                            onRemove={this.onRemove.bind(this, 'body_type_id')}
                                            displayValue="label"
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            selectedValues={formData && formData.body_type_id && filterData.bodyType ? filterData.bodyType.filter(e => formData.body_type_id.includes(e.value)) : []}
                                            autoComplete={false}
                                            ref={this.multiselectRef.body_type_id}
                                        />
                                    </div>

                                    <div className="col-sm-12 form-group">
                                        <label>{this.props.t('recomendTab.search.fuelType')}</label>
                                        <Multiselect
                                            id='fuel_type_id'
                                            options={filterData.fuelType}
                                            onSelect={this.onSelect.bind(this, 'fuel_type_id')}
                                            onRemove={this.onRemove.bind(this, 'fuel_type_id')}
                                            displayValue="label"
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            autoComplete={false}
                                            selectedValues={formData && formData.fuel_type_id && filterData.fuelType ? filterData.fuelType.filter(e => formData.fuel_type_id.includes(e.value)) : []}
                                            ref={this.multiselectRef.fuel_type_id}
                                        />
                                    </div>
                                    <div className="col-sm-12 form-group">
                                        <label>{this.props.t('recomendTab.search.make')}</label>                                        
                                        <Multiselect
                                            id='car_make'
                                            options={filterData.make}
                                            onSelect={this.onSelect.bind(this,'car_make')} 
                                            onRemove={this.onRemove.bind(this,'car_make')} 
                                            displayValue="label" 
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            autoComplete={false}
                                            selectedValues={formData && formData.make_ids && filterData.make ? filterData.make.filter(e => formData.make_ids.includes(e.value)) : []}
                                            ref={this.multiselectRef.car_make}
                                        />
                                    </div>
                                    <div className="col-sm-12 form-group">
                                        <label>{this.props.t('recomendTab.search.model')}</label>                                        
                                        <Multiselect
                                            id='car_model'
                                            options={filterData.model}
                                            onSelect={this.onSelect.bind(this,'car_model')} 
                                            onRemove={this.onRemove.bind(this,'car_model')} 
                                            displayValue="label" 
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            autoComplete={false}
                                            selectedValues={formData && formData.model_ids && filterData.model ? filterData.model.filter(e => formData.model_ids.includes(e.value)) : []}
                                            ref={this.multiselectRef.car_model}
                                        />
                                    </div>
                                    <div className="col-sm-12 form-group">
                                        <label>{this.props.t('recomendTab.search.color')}</label>
                                        <Multiselect
                                            id='color'
                                            options={filterData.color || []}
                                            onSelect={this.onSelect.bind(this,'color')} 
                                            onRemove={this.onRemove.bind(this,'color')} 
                                            displayValue="label" 
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            autoComplete={false}
                                            selectedValues={(formData && formData.selected_color && formData.selected_owner.length) ? formData.selected_color : ''}
                                            ref={this.multiselectRef.color}
                                        />
                                    </div>
                                    <div className="col-sm-12 form-group">
                                        <label>{this.props.t('recomendTab.search.city')}</label>
                                        <Multiselect
                                            id='city'
                                            options={filterData.cityList}
                                            onSelect={this.onSelect.bind(this,'city')} 
                                            onRemove={this.onRemove.bind(this,'city')} 
                                            displayValue="label" 
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            autoComplete={false}
                                            selectedValues={formData && formData.city && filterData.cityList ? filterData.cityList.filter(e => formData.city.includes(e.value)) : []}
                                            ref={this.multiselectRef.city}
                                        />
                                    </div>
                                    <div className="col-sm-12 form-group">
                                        <label>{this.props.t('recomendTab.search.dealer')}</label>
                                        
                                        <Multiselect
                                            id='dealer_ids'
                                            options={filterData.dealers}
                                            onSelect={this.onSelect.bind(this,'dealer_ids')} 
                                            onRemove={this.onRemove.bind(this,'dealer_ids')} 
                                            displayValue="label" 
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            autoComplete={false}
                                            selectedValues={formData && formData.dealer_ids && filterData.dealers ? filterData.dealers.filter(e => formData.dealer_ids.includes(e.value)) : []}
                                            ref={this.multiselectRef.dealer}
                                        />
                                    </div>
                                    <div className="col-sm-12 form-group">
                                        <label>{this.props.t('recomendTab.search.priority')}</label>
                                        
                                        <Multiselect
                                            id='dealer_type'
                                            options={config.constants.DEALER_TYPE_PRIORITY}
                                            onSelect={this.onSelect.bind(this,'dealer_type')} 
                                            onRemove={this.onRemove.bind(this,'dealer_type')} 
                                            displayValue="label" 
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            autoComplete={false}
                                            selectedValues={formData && formData.dealer_type ? config.constants.DEALER_TYPE_PRIORITY.filter(e => formData.dealer_type.includes(e.value)) : []}
                                            ref={this.multiselectRef.dealer_type}
                                        />
                                    </div>
                                {/* <div className="col-sm-12 form-group">
                                    <label>{this.props.t('recomendTab.search.makemodel')}</label>
                                    <Multiselect
                                        id='model_ids'
                                        options={filterData.models}
                                        onSelect={this.onSelect.bind(this,'model_ids')} 
                                        onRemove={this.onRemove.bind(this,'model_ids')} 
                                        displayValue="label" 
                                        showCheckbox={false}
                                        closeOnSelect={false}
                                        selectedValues={(formData && formData.selected_model_ids && formData.selected_model_ids.length) ? formData.selected_model_ids : ''}
                                        ref={this.multiselectRef.model_ids}
                                    />
                                </div> */}
                                

                                <div className="col-sm-12 form-group">
                                    <label>{this.props.t('recomendTab.search.customerLocality')}</label>
                                    <Select
                                        id="customer_locality"
                                        name="customer_locality"
                                        onChange={this.handleChange.bind(this,'customer_locality')}
                                        options={clusterListData}
                                        value={formData && clusterListData.filter((customer_locality) => Number(customer_locality.value) === Number(formData.customer_locality))}
                                        isClearable
                                            />
                                </div>

                                {/* <div className="col-sm-12 form-group">
                                    <label>{this.props.t('recomendTab.search.rTOCodeCity')}</label>
                                    <Multiselect
                                        id='rto_code_city'
                                        options={filterData.rtoList}
                                        onSelect={this.onSelect.bind(this,'rto_code_city')} 
                                        onRemove={this.onRemove.bind(this,'rto_code_city')} 
                                        displayValue="label" 
                                        showCheckbox={false}
                                        closeOnSelect={false}
                                        //selectedValues={(formData.selected_model_ids && formData.selected_model_ids.length) ? formData.selected_model_ids : ''}
                                        ref={this.multiselectRef.rto_code_city}
                                    />
                                </div> */}
                                
                                {/* <div className="col-sm-4 form-group">
                                    <label>{this.props.t('recomendTab.search.owner')}</label>
                                    <Multiselect
                                        id='owner'
                                        options={filterData.owner}
                                        onSelect={this.onSelect.bind(this,'owner')} 
                                        onRemove={this.onRemove.bind(this,'owner')} 
                                        displayValue="label" 
                                        showCheckbox={false}
                                        closeOnSelect={false}
                                        selectedValues={(formData && formData.selected_owner && formData.selected_owner.length) ? formData.selected_owner : ''}
                                        ref={this.multiselectRef.owner}
                                    />
                                </div> */}
                                
                                {/* <div className="col-sm-4 form-group">
                                    <label>{this.props.t('recomendTab.search.bodyType')}</label>
                                    <Multiselect
                                        id='req_body_type'
                                        options={filterData.bodyType}
                                        onSelect={this.onSelect.bind(this,'req_body_type')} 
                                        onRemove={this.onRemove.bind(this,'req_body_type')} 
                                        displayValue="label" 
                                        showCheckbox={false}
                                        closeOnSelect={false}
                                        selectedValues={(formData && formData.selected_req_body_type && formData.selected_req_body_type.length) ? formData.selected_req_body_type : ''}
                                        ref={this.multiselectRef.req_body_type}
                                    />
                                </div> */}
                                
                                 {/* <div className="col-sm-4 form-group">
                                    <label>{this.props.t('recomendTab.search.transmission')}</label>
                                    <Multiselect
                                        id="uc_transmission_id"
                                        name="uc_transmission_id"
                                        onSelect={this.onSelect.bind(this,'uc_transmission_id')} 
                                        onRemove={this.onRemove.bind(this,'uc_transmission_id')} 
                                        options={filterData.transmission}
                                        selectedValues={this.state.selectedTransmission}
                                        displayValue="label" 
                                        closeOnSelect={false}
                                        ref={this.multiselectRef.uc_transmission_id}
                                        selectedValues={formData && formData.uc_transmission_id && filterData.transmission? filterData.transmission.filter(e=>formData.uc_transmission_id.includes(e.value)):[]}
                                    />
                                </div> */}
                                {/* <div className="col-sm-12 form-group">
                                    <label>{this.props.t('recomendTab.search.color')}</label>
                                    <Multiselect
                                        id='color'
                                        options={COLOUR_ARR}
                                        onSelect={this.onSelect.bind(this,'color')} 
                                        onRemove={this.onRemove.bind(this,'color')} 
                                        displayValue="label" 
                                        showCheckbox={false}
                                        closeOnSelect={false}
                                        selectedValues={(formData && formData.selected_color && formData.selected_owner.length) ? formData.selected_color : ''}
                                        ref={this.multiselectRef.color}
                                    />
                                </div> */}
                                
                                {/* <div className="col-sm-12 form-group">
                                    <label>{this.props.t('recomendTab.search.dealer')}</label>
                                    <Multiselect
                                        id='dealer'
                                        options={filterData.dealerList}
                                        onSelect={this.onSelect.bind(this,'dealer')} 
                                        onRemove={this.onRemove.bind(this,'dealer')} 
                                        displayValue="label" 
                                        showCheckbox={false}
                                        closeOnSelect={false}
                                        //selectedValues={(multipleSelectedData.selected_color && multipleSelectedData.selected_owner.length) ? multipleSelectedData.selected_color : ''}
                                        ref={this.multiselectRef.dealer}
                                    />
                                </div> */}
                                
                                {/* <div className={ this.state.advanceSearchEnable ? "col-sm-12 form-group " : "col-sm-12 form-group hide"}>
                                    <label><strong>{this.props.t('recomendTab.search.showOnly')}</strong></label>
                                    <div className="clearfix rc-inline">
                                        <CheckBox type="checkbox" label="Trustmark" name="Trustmark" id="Trustmark" onChange={this.advanceSearch.bind(this,'trustmark')}/>
                                        <CheckBox type="checkbox" label="With Pictures" name="WithPictures" id="WithPictures" onChange={this.advanceSearch.bind(this,'trustmark')}/>
                                        <CheckBox type="checkbox" label="Dealer Cars" name="DealerCars" id="DealerCars" onChange={this.advanceSearch.bind(this,'DealerCars')}/>
                                        <CheckBox type="checkbox" label="With Sunroof" name="WithSunroof" id="WithSunroof" onChange={this.advanceSearch.bind(this,'WithSunroof')}/>
                                        <CheckBox type="checkbox" label="IndividualCars" name="IndividualCars" id="IndividualCars" onChange={this.advanceSearch.bind(this,'IndividualCars')}/>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-12 form-group">
                                    <span className="text-link" onClick={this.ToogleAdvanceSearch}> {this.state.advanceSearchEnable ? "- Less Search" : "+ Advance Search"} </span>
                                </div> */}
                                <div className="col-sm-12 form-group">
                                    {
                                        btnStatus
                                        ?
                                        <button type="button" className="btn btn-primary btn-block" onClick={this.getFilterList.bind(this)}>{this.props.t('recomendTab.search.submit')}</button>
                                        :
                                        <button type="button" className="btn btn-primary btn-block" disabled onClick={this.getFilterList.bind(this)}>{this.props.t('recomendTab.search.submit')}</button>
                                    }
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
            
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    filterList: state.lead.filterList,
    makeModelVersionList: state.lead.makeModelVersionList,
    cityList: state.lead.cityStateList,
    rtoList:state.lead.rtoList,
    dealerListData:state.dealer.dealerListData,
    customerLocalityList: state.lead.customerLocalityList
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            saveCarRecomend:bindActionCreators(
                LeadActions.saveCarRecomend,
                dispatch
            ),
            getCustomerLocality: bindActionCreators(
                LeadActions.getCustomerLocality,
                dispatch
            )
            /////// call get used car list here //////
        }
    }
}

export default withTranslation('lead')(connect(mapStateToProps, mapDispatchToProps)(RecommendSearchcar));
