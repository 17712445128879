import secureStorage from './encrypt';

//GET COUNTRY CODE FROM ENV    
let countryCode = process.env.REACT_APP_COUNTRY_CODE;
let appLanguage = process.env.REACT_APP_DEFAULT_LANG;

//IMPORT COUNTRY CONSTANTS
var path = './'+countryCode+'_config/constant';
var req = require.context("./", true);
var constants = req(path);


//SET LANG
// if(secureStorage.getItem('lang') && secureStorage.getItem('lang') !== appLanguage){
//     secureStorage.setItem('lang', appLanguage);
// }

//SET COUNTRY CODE IN STORAGE
secureStorage.setItem('countryCode', countryCode);

let configPath = countryCode+'_config/';
secureStorage.setItem('CONFIG_PATH',configPath);

const CONFIG={};
CONFIG.constants = constants;

//SET GATEWAY URL
let REACT_APP_GETWAY_API = process.env.REACT_APP_GETWAY_API;

CONFIG.API_URL = {
    GETWAY_API: REACT_APP_GETWAY_API,
    SAMPLE_LEAD_BLOCK_CSV: require("../public/files/sample_blocked_upload.csv"),
    SAMPLE_BULK_UPLOAD_CSV: require("../public/files/sample_bulk_upload.csv"),
    LANG: appLanguage
}

CONFIG.LIST_ID = 2;
CONFIG.DIALER_TYPE = 'L2';
CONFIG.SUCCESS_PAGE_IMG = require("../../src/webroot/images/success.png");
CONFIG.ERROR_PAGE_IMG = require("../../src/webroot/images/error.png");

export default CONFIG ;
