import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import DateFormat from 'dateformat';
import { connect } from 'react-redux';
import queryString from 'query-string'
import * as helper from '../../config/helper';


class AddLeadsDialer extends Component {
    constructor(props) {
        super(props);
        
    
        this.state = {
            modalData: {},
            loading: false
        };
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let updatedStateData = {};
        let queryParam = queryString.parse(nextProps.location.search);

        updatedStateData['modalData'] = nextProps.modalData;
        if(nextProps.modalData.addToDialerResp && Object.keys(nextProps.modalData.addToDialerResp).length){
            updatedStateData['loading'] = false;
            AddLeadsDialer.sendResponse(nextProps, nextProps.modalData.addToDialerResp);
        }

        //UPDATE CLUSTER/CITY FOR QUERY PARAMS FILTERS
        updatedStateData.modalData['filterFields'].forEach((el,key)=>{
            if(el['name'] === 'cluster'){
                if(!el['value'] && queryParam['cluster']){
                    queryParam['cluster'] = queryParam['cluster'].split(',');

                    if(nextProps['clusterData'] && nextProps['clusterData']['data']){
                        let selectedClusters = nextProps['clusterData']['data'].filter(el=>queryParam['cluster'].includes(''+el.id));
                        updatedStateData['modalData']['filterFields'][key]['value'] = helper.getDataInKeyValueFormat(selectedClusters,'')
                    }
                }

            }

            if(el['name'] === 'city'){
                if(!el['value'] && queryParam['city']){
                    queryParam['city'] = queryParam['city'].split(',');

                    if(nextProps['cityListData'] && nextProps['cityListData']['data'] && nextProps['cityListData']['data']['city']){
                        let selectedCities = nextProps['cityListData']['data']['city'].filter(el=>queryParam['city'].includes(''+el.id));
                        updatedStateData['modalData']['filterFields'][key]['value'] = helper.getDataInKeyValueFormat(selectedCities,'')
                    }
                }
            }
        });

        return updatedStateData

    }

    static sendResponse = (props, data) => {
        if(data.status === 200){
            toast.success(data.message)
        }else{
            // toast.error('Error in sending leads to dialer')
        }
        props.modalClose();
    }

    closeModal = () => {
        this.props.modalClose();
    }

    addToDialer = () => {
        this.setState({loading: true, modalData: {...this.state.modalData, addToDialerResp: {}}});
        this.props.addToDialer();

    }

    render() {        
        const {modalData, loading} = this.state;
        return (
            <div>
                <div className="row">
                        <div className="col-md-12 feedback-newdeisgn">
                            <div className="col-sm-12">
                                {
                                     
                                    modalData.filterFields && modalData.filterFields.map((el,key)=>{
                                        if(el.label !== 'search.search'){
                                            return(<div className="col-sm-6" key={key}>
                                                <strong>{this.props.t(el.label)}</strong>: {
                                                    (el.type === 'multiselect' && el.value)
                                                    ?
                                                        (el.name === 'rating')
                                                        ?
                                                            el.option.map((val,key)=>{
                                                                return(
                                                                    (el.value.includes(val.value)) ? ((key !== el.value.length-1) ? val.label+', ' : val.label) : ''
                                                                )
                                                            })
                                                        :
                                                            el.value.map((val,key)=>{
                                                                return(
                                                                    (key !== el.value.length-1) ? val.label+', ' : val.label
                                                                )
                                                            })
                                                    :                                                       
                                                    (
                                                        (el.type === 'dateTime' || el.type === 'date')
                                                        ?
                                                            (el.value) ? DateFormat(el.value, 'dd/mm/yyyy, h:MM:ss TT') : ''
                                                        :
                                                            (el.value && el.value.label) ? el.value.label : el.value
                                                    )
                                                    
                                                    
                                                    }
                                                    
                                            </div>)
                                        }
                                        else{
                                            return '';
                                        }
                                    })
                                }
                            </div>
                            <div className="col-sm-12">
                                <p className="text-center"><strong>{(modalData.selectedLeads || 0)  + ' Lead(s) selected'}</strong></p>
                            </div>
                            <div className="text-center" id="">
                                {
                                    loading
                                    ?
                                    <button type="button" className="btn btn-primary" disabled >Please wait</button>
                                    :
                                    <button type="button" className="btn btn-primary" onClick={this.addToDialer} >Submit</button>

                                }
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-default" onClick={this.closeModal} >Close</button>
                            </div>
                        </div>
                    </div>
                
            </div>
            
        );
    }
}

const mapStateToProps = state => ({
    cityListData: state.inventory.cityListData || {},
    clusterData: state.dealer.clusterData

});


const mapDispatchToProps = dispatch => {
    return {}
}

export default withTranslation('lead') (withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLeadsDialer)));

