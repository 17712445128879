import MasterService from './MasterService';

const LeadService = {
    ...MasterService,    
    getLeadList(postdata){
        if(postdata.id){
            return this.post('/lead/listLead',postdata) 
        }else{
            return this.post('/lead/listLeadData',postdata) 
        }
    },
    getAllUserList(){
        return this.post('/user/listAllUser') 
    },
    getCityList(postdata){
        return this.post('/city/listCity',postdata) 
    },
    getStatusList(postdata){
        return this.post('/status/listStatus',postdata) 
    },
    getSubStatusList(postdata){
        return this.post('/status/listSubStatus',postdata) 
    },
    getSourceList(postdata){
        return this.post('/source/listSource',postdata) 
    },
    getSubSourceList(postdata){
        return this.post('/source/listSubSource',postdata) 
    },
    getCarMakeModelList(postdata){
        return this.post('/carMake/listCarMakeModel',postdata) 
    },
    getMakeModelVersionList(postdata){
        return this.post('carMake/listCarMakeModelVariant', postdata)
    },
    addLead(postdata){
        return this.post('/lead/saveLead',postdata)
    },
    getfilterData(){
        return this.get('/inventory/getfilter')
    },
    updateCustomer(postdata){
        return this.post('/customer/editCustomer',postdata) 
    },
    saveRequirementForm(postdata){
        return this.post('/customer/saveCustomerRequirement',postdata) 
    },
    getAgentDetails(postdata){
        return this.post('/customer/getAgentDetails',postdata) 
    },
    listDealers(postData){
        return this.post('/dealer/listDealers', postData);
    },
    getTimelineDetails(postdata){
        return this.post('/lead/listTimeLine',postdata) 
    },
    getLeadsCarList(postdata){
        return this.post('/lead/listLeadsCar',postdata) 
    },
    getUsedStockcarDetails(postData) {
        return this.post('/inventory/getCarDetails',postData)
    },
    getCustomers(postdata){
        return this.post('/customer/getCustomer',postdata) 
    },
    saveConversion(postdata){
        return this.post('/lead/saveConversion',postdata) 
    },
    getBlockedClosedLeads(postdata){
        return this.post('/lead/searchBlockedLeads', postdata);
    },
    unblockLead(postdata){
        return this.post('/lead/unblockLead', postdata);
    },
    submitBulkUpload(postdata){
        return this.post(`/lead/${postdata['action']}`, postdata);
    },
    getWalkingDetails(postData){
        return this.post('/lead/getWalkinDetails',postData);
    },
    getConversionInfoList(postdata){
        return this.post('/lead/conversionInfoList', postdata);
    },
    updateConversion(postdata){
        return this.post('/lead/updateConversion', postdata);
    },
    saveSubLeadDetails(postdata){
        return this.post('/lead/saveSubLead', postdata);
    },
    sendManualSMSForEvent(postdata){
        return this.post('/lead/sendManualSMS', postdata); 
    },
    getStockList(postData){
        return this.post('/inventory/listInventoryStock',postData)
    },
    importXlsFile(postdata){
        return this.post('/lead/importXlsFile', postdata);
    },
    getCallStatusList(postdata){
        return this.post('/status/listCallStatus',postdata) 
    },
    saveMainLeadDetails(postdata){
        return this.post('/lead/saveMainLead', postdata);
    },
    saveSubLeadOutstation(postdata){
        return this.post('/lead/saveSubLeadOutstation',postdata)
    },
    copyCarDetails(postData){
        return this.post('/lead/copyCarDetails',postData)
    },
    saveRecomendCar(postData){
        return this.post('/lead/recomendCar',postData)
    },
    getWalkinFeedback(){
        return this.post('/lead/walkinFeedback')
    },
    getCityState(){
        return this.get('/inventory/getStateCityList');
       // return this.post('/city/getCityState',postdata) 
    },
    getUserNameByUserType(postdata){
        return this.post('/lead/getUserNameByUserType',postdata) 
    },
    getRTOList(){
        return this.post('/city/getRTOList') 
    },
    getNewCarPrice(postData){
        return this.post('/lead/newCarPrice',postData) 
    },
    saveWalkingPurchaseDetails(postData){
        return this.post('/lead/saveWalkinPurchaseInfo',postData) 
    },
    getWalkinPurchaseData(postData){
        return this.get('/lead/getWalkinPurchaseInfo',postData) 
    },
    getDealerDetailsById(postData){
        return this.post('/dealer/getDealerDetailsById', postData);
    },
    saveDealerFlag(postData){
        return this.post('/dealer/saveDealerFlag', postData);
    },
    getCustomerLocality(postData){
        return this.post('/customer/getCustomerLocality', postData);
    },
    getConversionStatus(postData){
        return this.post('/lead/getConversionStatus', postData);
    },
    makeSellerLead(postData){
        return this.post('/lead/makeSellerLead',postData)
    },
    saveClosedLeadInfo(postData){
        return this.post('/lead/saveClosedLeadInfo',postData)
    },
    getWalkinFeedbackList() {
        return this.get('/lead/getWalkinFeedbackList',{});
    },
    getStockListByIds(postData){
        return this.post('/inventory/listInventoryStockByids',postData)
    },
    saveWalkinPurchaseInfo(postData){
        return this.post('/lead/saveWalkinPurchaseInfo',postData)
    },
    getDialerLeads(postData) {
        return this.post('/lead/getDialerLeads',postData)
    },
    importLeadFinderData(postdata){
        return this.post('/lead/importLeadFinderData', postdata);
    },
    getNotificationDetail(queyParam){
        return this.get('/user/getNotificationDetail', queyParam)
    },
    updateRescheduledDate(postData){
        return this.post('/user/updateRescheduledDate', postData)
    },
    inventoryCsvExport(postdata){
        return this.post('/inventory/inventoryCsvExport', postdata);
    },
    leadFeedbackSubmit(postData){
        return this.post('/user/leadFeedbackSubmit', postData);
    },
    getFeedbackFormValidity(postData) {
        return this.post('/user/getFeedbackFormValidity', postData);
    },
    purchaseFeedbackSubmit(postData){
        return this.post('/user/purchaseFeedbackSubmit', postData);
    },
    downloadReportDetails(postData){
        return this.post('/report/downloadReportData', postData);
    },
    submitBulkUploadFbLeads(postdata){
        return this.post(`/lead/submitBulkUploadFbLeads`, postdata);
    }
}

 export default LeadService;
