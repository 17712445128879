import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import PhoneImg from "./assets/images/phone.svg";
import MailImg from "./assets/images/email.svg";

class Footer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      footerSettings: null
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if(nextProps.siteSettings && !prevState.siteSettings ){
        return ({ footerSettings: nextProps.siteSettings.footer })
    }
    return null;
  }

  render() {
    const { footerSettings } = this.state;
    const currentPath = window.location.pathname;

    
          
        
              if(!currentPath.includes('error-page') && !currentPath.includes('view-purchase-feedback') && !currentPath.includes('purchase-feedback') && !currentPath.includes('view-feedback') && !currentPath.includes('thankyou-page') && !currentPath.includes('feedback-form') && !currentPath.includes('walkin-reschedule') && !currentPath.includes('login') && !currentPath.includes('-password'))
              {
                return (<footer>
                  <div className="footer-container clearfix" id="footer">
                    <div className="pull-left">
                      <ul className="footer-dealer">
                        <li>
                        {
                          footerSettings && footerSettings.copyright
                          ?
                          <p><i className="fa fa-copyright" aria-hidden="true"></i> {footerSettings.copyright}</p>
                          :
                          ''
                        }
                        </li>
                      </ul>
                    </div>
                    <div className="pull-right">
                      {
                        footerSettings && footerSettings.phone
                        ?
                        <span className="f-image"><img src={PhoneImg} alt="call-img" /><span className="font-12 pad-L5">{footerSettings.phone}</span><span className="pad-L5 pad-R5">|</span></span>
                        :
                        ''
                      }
                      {
                        footerSettings && footerSettings.email
                        ?
                        <span className="f-image"><img src={MailImg} alt="mail-img" /> <a href={`mailto:${footerSettings.email}`} target="_blank">{footerSettings.email}</a></span>
                        :
                        ''
                      }
                    </div>
                  </div>
                </footer>)
              }else{
                return("");
              }
  }
}

const mapStateToProps = state => {
  return {
     siteSettings: state.auth.siteSettings
  }
}

export default withTranslation('common') (withRouter(connect(mapStateToProps)(Footer)));